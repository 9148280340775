<template>
    <Modal :activeModal="activeModal" @closeModal="closeModal" :widthModal="1090">
      <v-card>
        <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
          Relatório Bônus Cupons
          <v-spacer></v-spacer>
          <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
        </v-toolbar>
  
        <v-container fluid class="pb-0">
          <v-col>
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col class="pa-3 pt-0 pb-1">
                    <strong>Lista de cupons: {{ text }}</strong>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-container>
  
        <v-col class="d-flex justify-center align-center" style="min-height: 300px; width: 100%;" v-if="loadingModal">
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-col>
  
        
        <v-container fluid class="mt-0" v-else>
          <v-col>
            <div class="box-table">
              <v-data-table
                :headers="headers"
                :items="listaItens"
                :items-per-page="5"
                :mobile-breakpoint="300"
                width="300px"
                height="auto"
                flat
                dense
              >
                <!-- <template v-slot:body="{ items }">
                  
                </template> -->
  
                <template v-slot:item.winner_value="{ item }">
                    {{ item.winner_value || '-' }}
                </template>

                <template v-slot:item.status="{ item }">
                    {{ item.status || '-' }}
                </template>
  
              </v-data-table>
            </div>
          </v-col>
        </v-container>
      </v-card>
    </Modal>
  </template>
  
  <script>
  import { mapActions, mapGetters } from 'vuex'
  import { validationMixin } from 'vuelidate'
  // import { required } from 'vuelidate/lib/validators'
  import Events from '@/core/service/events'
  // eslint-disable-next-line
  import variables from '@/assets/styles/helpers/_variables.scss'

  const namespaceStore = 'bonusCupons'
  
  export default {
    name: 'ModalListaBonusCupons',
    mixins: [validationMixin],
    props: ['entity'],
    components: {
      Modal: () => import('@/views/components/modal'),
    },
    data: () => ({
      activeModal: false,
      text: '',
      loading: false,
      loadingModal: false,      
      headers: [
        { sortable: false, class: 'table-header', text: 'Bilhete', value: 'base36_encode' },
        { sortable: false, class: 'table-header', text: 'Número', value: 'guess' },
        { sortable: false, class: 'table-header', text: 'Prêmio', value: 'winner_value' },
        // { sortable: false, class: 'table-header', text: 'Status', value: 'status' },
      ]
    }),
    
    computed: {
      ...mapGetters(namespaceStore, {
        'listaItens': 'listCupons', 
        'totalItens': 'totalCupons' 
      }),
    },
    mounted () {
      const self = this
      Events.$on('open::modal::relatorio::cupons', (e) => {
        self.activeModal = true
        self.loadingModal = true
        self.text = e.text.text
        delete e.text
        self.buscarItem(e).finally(() => { self.loadingModal = false })
      })
    },

    methods: {
      ...mapActions(namespaceStore, ['buscarItem', 'limparItem']),
      openModal () {
        this.activeModal = true
      },
  
      closeModal () {
        this.activeModal = false
        this.limparItem()
      }
    },
  
    beforeDestroy () {
      Events.$off('open::modal::relatorio::cupons')
    }
  }
  </script>
  
  <style lang="scss">
    @import '~@/assets/styles/components/formularios';
    @import '~@/assets/styles/components/modal-cadastro';
    .box-table .v-data-table > .v-data-table__wrapper > table > tbody > tr > td span.color-red{
      color: lightcoral;
    }
  </style>